import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import "./admin.css";
import axios from "axios";
import { BASE_URL } from "../../service/auth";
import { useEffect, useState } from "react";
import Loader from "../client/Loader";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { ArrowBack, ArrowRight } from "@mui/icons-material";

const WayTable = () => {
  let a = "";
  const [deliveryList, setDeliveryList] = useState([]);
  const [pageSize, setPageSize] = useState(Number);
  const [pageId, setPageId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(Number);
  const [statuss, setStatus] = useState([]);
  const [statusId, setStatusId] = useState(Number);
  const [comment, setComment] = useState("");
  const [order, setOrder] = useState({});

  const getStatusData = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/c_dashboard/status_views/`);
      console.log(data);
      setStatus(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDeliveryListData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${BASE_URL}/b_api/sayts/delivery_all/?page=${pageId}`
      );
      setDeliveryList(data?.data?.results);
      setPageSize(data?.data?.count);
      console.log(data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useState(() => {
    getDeliveryListData();
    getStatusData();
  }, []);

  useEffect(() => {
    getDeliveryListData();
  }, [pageId]);
  if (isLoading) {
    return <Loader />;
  }


  function renderElement(option, item) {
    switch (option) {
      case "ru":
        return <div>{item?.translations?.nl?.name}</div>;
      case "gl":
        return <div>{item?.translations?.nl?.name}</div>;
      case "en":
        return <div>{item?.translations?.en?.name}</div>;
      case "fr":
        return <div>{item?.translations?.fi?.name}</div>;
      default:
        return <div>Option not recognized</div>;
    }
  }

  const handleEdit = (id) => {
    setIsEdit(true);
    setEditId(id);
  };

  const statusHandler = async () => {
    try {
      await axios.put(
        `${BASE_URL}/b_api/sayts/delivery_update_views/${editId}/`,
        { id_status: statusId, comment }
      );
      getDeliveryListData();
      setIsEdit(false);
      setStatusId(0);
      setEditId(0);
      toast.success("Status was changed");
    } catch (error) {
      console.log(error);
    }
  };

  console.log(deliveryList);

  return (
    <div className="min-h-[40vh]">
      <div className="w-full overflow-x-auto bg-transparent">
        <div className="min-w-[800px] inline-block rounded-lg overflow-x-auto border border-gray-300">
          <table className="min-w-[800px] w-full table-auto border border-gray-300">
            <thead className="border-b border-gray-300">
              <tr>
                <th className="text-center min-w-[150px] text-md text-lightGreey font-semibold border-r border-gray-300">
                  Идентификатор <br />
                  загрузки
                </th>
                <th className="text-center min-w-[200px] text-lightGreey font-semibold border-r border-gray-300">
                  Имя клиента
                </th>
                <th className="text-center min-w-[150px] text-lightGreey font-semibold border-r border-gray-300">
                  Вид груз
                </th>
                <th className="text-center min-w-[150px] text-md text-lightGreey font-semibold border-r border-gray-300">
                  Клиентская <br />
                  нагрузка
                </th>
                <th className="text-center min-w-[150px] text-lightGreey font-semibold border-r border-gray-300">
                  Эл. почта
                </th>
                <th className="text-center min-w-[150px] text-lightGreey font-semibold border-r border-gray-300">
                  Телефон
                </th>
                <th className="text-center min-w-[120px] text-lightGreey font-semibold border-r border-gray-300">
                  Дата заказа
                </th>
                <th className="text-center min-w-[100px] text-md text-lightGreey font-semibold border-r border-gray-300">
                  Дата получения
                </th>
                <th className="text-center min-w-[100px] text-lightGreey font-semibold border-r border-gray-300">
                  Дата поставки
                </th>
                <th className="text-center min-w-[150px] text-lightGreey font-semibold border-r border-gray-300">
                  Статус
                </th>
                <th className="text-center min-w-[70px] border-gray-300"></th>
              </tr>
            </thead>
            <tbody>
              {deliveryList?.map((c) => (
                <tr key={c?.uuid} className="border-b border-gray-300">
                  <td className="text-center text-md font-medium text-lightGreey border-r border-gray-300">
                    {c?.id}
                  </td>
                  <td className="text-center px-3 line-clamp-1 text-md font-medium text-lightGreey border-r border-gray-300">
                    {c?.user_id?.first_name}
                  </td>

                  <td className="text-center text-md font-medium text-lightGreey border-r border-gray-300">
                    {c?.is_transport ? "Авто полностью" : "Сборный груз"}
                  </td>

                  <td className="text-center text-md font-medium text-lightGreey border-r border-gray-300">
                    1110
                  </td>
                  <td className="text-center px-3 text-md font-medium text-lightGreey border-r border-gray-300">
                    {c?.user_id?.email}
                  </td>
                  <td className="text-center px-3 text-md font-medium text-lightGreey border-r border-gray-300">
                    {c?.user_id?.phone}
                  </td>
                  <td className="text-center text-md font-medium text-lightGreey border-r border-gray-300">
                    {c?.create_date}
                  </td>
                  <td className="text-center text-md font-medium text-lightGreey border-r border-gray-300">
                    {c?.acceptance_date ? c?.acceptance_date : "-"}
                  </td>
                  <td className="text-center text-md font-medium text-lightGreey border-r border-gray-300">
                    {c?.delivery_date ? c?.delivery_date : "-"}
                  </td>
                  <td className="text-center text-md font-medium text-lightGreey border-r border-gray-300">
                    {renderElement(i18n?.language, c?.id_status)}
                  </td>
                  <td className="text-center align-middle">
                    <div className="flex justify-center items-center">
                      <div
                        role="button"
                        onClick={() => {
                          handleEdit(c?.id);
                          setOrder(c);
                        }}
                      >
                        <img
                          src={"/edit.svg"}
                          width={20}
                          height={20}
                          alt={"edit"}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-8 flex justify-end">
        <Pagination
          page={pageId}
          count={
            pageSize % 10 !== 0
              ? Math.floor(pageSize / 10) + 1
              : Math.floor(pageSize / 10)
          }
          onChange={(e, value) => {
            setPageId(value);
          }}
          variant="outlined"
          shape="rounded"
        />
      </div>



      {isEdit && (
        <div className="fixed top-0 left-0 flex items-center justify-center bg-modalBg z-50 w-full h-screen">
          <div className="w-[96%] sm:w-[94%] md:w-[50%] mx-auto bg-white min-h-[22vh] rounded-md p-4">
            <form className="w-full flex flex-col gap-y-4 overflow-y-auto">
              {/* clear button */}
              <div className="flex w-[100%] justify-end">
                <div
                  onClick={() => setIsEdit(false)}
                  className="w-[12px] h-[12px] sm:w-[18.84px] sm:h-[18.84px] cursor-pointer"
                >
                  <img
                    src={"/clear.svg"}
                    alt="clear"
                    width={100}
                    height={100}
                    className="w-[100%] h-[100%]"
                  />
                </div>
              </div>

              <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
              <FormControl className="m-1 w-full space-y-4">
                <Select
                  defaultValue={"Kies"}
                  onChange={(e) => setStatusId(e.target.value)}
                >
                  <MenuItem value={"Kies"} disabled>
                    {t(`cal.step3.p6`)}
                  </MenuItem>
                  {statuss?.map((item) => (
                    <MenuItem value={item?.id}>
                      {/* {renderElement(i18n.language, item)} */}
                      {item?.translations?.nl?.name}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  label="Opmerkingen"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                />
                <div className="w-full flex flex-col gap-y-1">
                  <div>
                    <p className="mb-2 text-[#344054] font-medium">
                      Качество Груза: {order?.id_cargo?.hasOwnProperty("translations") ? order?.id_cargo?.translations?.ru?.name : "Не указано"}
                    </p>
                  </div>
                  <div className="w-full inline ">
                    <p><b>Откуда:</b> {order?.city?.name} <ArrowBack className="rotate-180" /> Имя/названия: {order?.streetNumber1} <ArrowBack className="rotate-180" /> Названия улицы: {order?.streetName1}  <ArrowBack className="rotate-180" />Номер улицы: {order?.apartmentOrOfficeNumber1}</p>
                  </div>

                  <div className="w-full flex justify-between">
                    <p><b>Куда:</b> {order?.city?.name2} <ArrowBack className="rotate-180" />Имя/названия: {order?.streetNumber2} <ArrowBack className="rotate-180" /> Названия улицы: {order?.streetName2}  <ArrowBack className="rotate-180" />Номер улицы: {order?.apartmentOrOfficeNumber2}</p>
                  </div>
                  <p>Город: {order?.user_id?.id_city ? order?.user_id?.id_city : "Не указано"}</p>
                  <p>Почтовый индекс: {order?.user_id?.pochta_index ? order?.user_id?.pochta_index : "Не указано"}</p>

                  <div className="w-full inline">
                    <p>Расстояние: {order?.km} km</p
                    >
                  </div>

                  <div className="flex xl:flex-row flex-col gap-x-4 gap-y-4">
                    <div className="xl:w-1/2 w-full">
                      {order?.product?.map(c => (
                        <>
                          <div className="w-full flex justify-between">
                            <p>Длина: {c?.length} м </p>
                          </div>

                          <div className="w-full flex justify-between">
                            <p>Ширина: {c?.width} м </p>
                          </div>

                          <div className="w-full flex justify-between">
                            <p>Высота: {c?.height} м </p>
                          </div>

                          <div className="w-full flex justify-between">
                            <p>Вес: {c?.weight} кг </p>
                          </div>

                          <div className="w-full flex justify-between">
                            <p>Штук: {c?.count} </p>
                          </div>
                          <b>Откуда:</b>
                          <p><b>Комментарий:</b> {order?.comment1}</p>
                          {/* <p>Номер дома(пользователя): {order?.user_id?.number ? (order?.user_id?.street + ", " + order?.user_id?.number) : "Не указано"}</p> */}
                        </>
                      ))}
                    </div>
                    <div className="xl:w-1/2 w-full flex flex-col gap-1">
                      <b>{order?.user_id?.first_name ? (order?.user_id?.first_name + ", " + order?.user_id?.last_name) : "Не указано"}</b>
                      <p>Логин: {order?.user_id?.username ? order?.user_id?.username : "Не указано"}</p>
                      <p>Телефон: {order?.user_id?.phone ? order?.user_id?.phone : "Не указано"}</p>
                      <p>E-mail: {order?.user_id?.email ? order?.user_id?.email : "Не указано"}</p>
                      <p>Цена: {order?.price ? order?.price : "Не указано"} €</p>
                      {/* <b>Адрес, улица, номер-дом: {order?.user_id?.address ? order?.user_id?.address + ", " + (order?.user_id?.street + ", " + order?.user_id?.number) : "Не указано"}</b> */}
                      <b>Куда:</b>
                      <p><b>Комментарий:</b> {order?.comment2}</p>

                    </div>
                  </div>
                </div>
              </FormControl>
              <Button
                onClick={statusHandler}
                type="button"
                variant="contained"
                className="capitalize my-2"
              >
                Wijziging
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default WayTable;
